<template>
  <v-navigation-drawer
    app
    absolute
    temporary
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-list
      shaped
    >
      <v-list-item
        v-for="name in items"
        :key="name"
        :to="{name: name}"
        color="primary"
      >
        <v-list-item-title v-text="name" />
      </v-list-item>

      <v-list-item>
        <menu-desplegable
          :menuData="desplegableItems"
          :ButtonName="'Mi Cuenta'"
        />
      </v-list-item>
    </v-list>

    <template v-slot:append>
      <login-card v-if="!accessToken"/>
      <signup-card v-if="!accessToken"/>
      <div
        class="pa-2"
      >
        <v-btn
          class="mr-4 my-2"
          v-if="accessToken && isAuth"
          :small="true"
          :to="{name: 'DashBoard'}"
          block
          text
        >
          <v-icon left>mdi-chart-line</v-icon>
          <div>dashBoard</div>
        </v-btn>

        <v-btn
          class="mr-4 my-2"
          v-if="accessToken"
          @click="logout"
          :small="true"
          block
          text
        >
          <v-icon left> mdi-logout </v-icon>
          Log Out
        </v-btn>
        
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
  name: 'HomeDrawer',
  props: {
    items: {
      type: Array,
      default: () => ([]),
    },
    desplegableItems: {
      type: Array,
      default: () => ([])
    }
  },
  components: {
    SignupCard: () => import('@/components/utils/accountDialogs/SignupCard.vue'),
    LoginCard: () => import('@/components/utils/accountDialogs/LoginCard.vue'),
    MenuDesplegable: () => import('@/components/utils/MenuDesplegable.vue'),
  },
  computed: {
    ...mapState('usuario', ['accessToken']),
    ...mapGetters('usuario', ['isAuth'])
  },
  methods: {
    ...mapActions('usuario', ['logout']),
  }
}
</script>
